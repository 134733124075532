import React, { useState } from 'react'
import { graphql } from 'gatsby'

// Libraries
import { map as _map } from 'lodash'
import { Nav, Accordion, useAccordionToggle } from 'react-bootstrap'

// Components
import {
  TitleDefault,
  TitleAlt,
  BannerDefault,
  ContentDefault,
  BoxDefault,
} from 'components/Elements'
import SEO from 'components/SEO'
import Layout from 'components/Layout'

// Styles
import 'styles/templates/FAQTemplate.scss'

// Icons
import chevronDown from 'img/icon/down-arrow-blue.svg'
import chevronUp from 'img/icon/up-arrow-blue.svg'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      content
      path
      acf {
        backgroundImage: background_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 800, toFormat: JPG) {
                src
              }
            }
          }
        }

        faq: groups {
          title
          questions {
            question
            answer
          }
        }
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

const FAQTemplate = ({
  data: {
    page: {
      title,
      content,
      path,
      acf: { backgroundImage, faq },
      yoast_meta,
    },
  },
}) => {
  const [panelIndex, setPanelIndex] = useState()

  const CustomToggle = ({ children, eventKey }) => {
    const customOnClick = useAccordionToggle(eventKey, () => {
      setPanelIndex(eventKey === panelIndex ? null : eventKey)
    })

    const customClass = eventKey === panelIndex ? 'open' : 'closed'

    return (
      <Nav.Link className={`p-0 ${customClass}`} onClick={customOnClick}>
        {children}
      </Nav.Link>
    )
  }

  return (
    <Layout backgroundImage={backgroundImage}>
      {backgroundImage && backgroundImage.localFile ? (
        <SEO
          yoast={{ meta: yoast_meta }}
          pathname={path}
          image={backgroundImage.localFile.childImageSharp.fixed.src}
          article
        />
      ) : (
        <SEO yoast={{ meta: yoast_meta }} pathname={path} />
      )}
      <div className="faq-template">
        <BannerDefault className="py-5">
          <div className="row">
            <div className="col-12 col-lg-6 py-4">
              <TitleDefault asH1 className="pl-lg-5">{title}</TitleDefault>
              <ContentDefault className="mt-4">
                <div dangerouslySetInnerHTML={{ __html: content }} />
              </ContentDefault>
            </div>
            <div className="col-12 col-lg-6" />
          </div>
        </BannerDefault>

        <div className="container pb-5">
          <BoxDefault>
            <ContentDefault className="px-3 px-lg-5 pt-5">
              {_map(faq, ({ title, questions }, groupIndex) => (
                <div className="faq mb-5" key={groupIndex}>
                  <TitleAlt>{title}</TitleAlt>
                  <Accordion className="mt-4">
                    {_map(questions, ({ question, answer }, index) => (
                      <div className="mt-3" key={index}>
                        <div>
                          <CustomToggle eventKey={`${groupIndex}-${index}`}>
                            <div className="d-flex justify-content-between w-100 text-left">
                              <div className="font-weight-l">{question}</div>
                              <div>
                                <img
                                  className="faq-chevron faq-chevron-up"
                                  src={chevronUp}
                                  alt=""
                                />
                                <img
                                  className="faq-chevron faq-chevron-down"
                                  src={chevronDown}
                                  alt=""
                                />
                              </div>
                            </div>
                          </CustomToggle>
                        </div>
                        <Accordion.Collapse eventKey={`${groupIndex}-${index}`}>
                          <div
                            className="pb-3"
                            dangerouslySetInnerHTML={{ __html: answer }}
                          />
                        </Accordion.Collapse>
                      </div>
                    ))}
                  </Accordion>
                </div>
              ))}
            </ContentDefault>
          </BoxDefault>
        </div>
      </div>
    </Layout>
  )
}

export default FAQTemplate
